import config from '../config';

const nonSecure = /http:\/\//i;
const httpPrefix = /https{0,1}:\/\//i;
const portNumber = /:(\d+)(\/|$)/;
const portSection = /(:\d+)\/{0,1}/;
const graphqlSuffix = /\/graphql$/i;

export function getUrl() {
  const address = config.get('api.address');
  // allow manual prepending of http or https
  // add https for the httpLink if nothing was specified
  let baseAddress = '';
  let secure = true;
  if (nonSecure.test(address)) {
    secure = false;
  }
  const fallbackPort = secure ? 443 : 8347;
  // assume an fallbackPort if nothing was specified
  const port = address.match(portNumber)?.[1] ?? fallbackPort;
  baseAddress = address.replace(graphqlSuffix, '');
  baseAddress = baseAddress.replace(httpPrefix, '');
  baseAddress = baseAddress.replace(portSection, '');

  return {
    domain: baseAddress,
    http: `http${secure ? 's' : ''}://${baseAddress}:${port}/graphql`,
    ws: `ws${secure ? 's' : ''}://${baseAddress}:${port}/graphql`,
  };
}

export function getHttpUrl() {
  return getUrl().http;
}

export function getWsUrl() {
  return getUrl().ws;
}

export function getDomain() {
  return getUrl().domain;
}
