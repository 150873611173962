import { Suspense, useCallback, useEffect } from 'react';
import { useLocation, useNavigate, useRoutes } from 'react-router-dom';
import { getIndexPath as getKPIOverviewPath } from '~/features/analysis/kpi-overview/routes';
import { getLoginPath } from '~/features/auth/routes';
import { getIndexPath as getProVersionPagePath } from '~/features/buy-pro-version/routes';
import { LoadingText } from '~/features/common/components/LoadingText';
import { useAuth } from '~/features/common/context/AuthContext';
import { getIndexPath as getPartsPath } from '~/features/parts/routes';
import { getIndexPath as getSimulationPath } from '~/features/simulation/routes';
import {
  getTimeSheetExportPath,
  getTimeTrackerAdminPath,
  getIndexPath as getTimeTrackerPath,
} from '~/features/timetracker/routes';
// @ts-expect-error types are ignore because of the dynamic imports
import routes from '~react-pages';
import { navigationShortcutList } from './features/common/components/Sidebar/components/SidebarNavigationLinks';
import { routeIsAllowed } from './graphql/fetcher';

const disallowedRoutes = [
  getKPIOverviewPath({}),
  getSimulationPath({}),
  getTimeTrackerPath({}),
  getPartsPath({}),
  getTimeSheetExportPath({}),
  getTimeTrackerAdminPath({}),
];

export const RenderRoutes = () => {
  const auth = useAuth();
  const navigate = useNavigate();
  const location = useLocation();

  const handleKeyPress = useCallback(
    (event: { shiftKey: boolean; ctrlKey: boolean; key: string | number }) => {
      if (event.shiftKey && event.ctrlKey) {
        const returnPathFunction = navigationShortcutList[event.key];
        if (returnPathFunction) {
          navigate(returnPathFunction({}));
        }
      }
    },
    []
  );

  useEffect(() => {
    document.addEventListener('keydown', handleKeyPress);
    return () => {
      document.removeEventListener('keydown', handleKeyPress);
    };
  }, [handleKeyPress]);

  useEffect(() => {
    if (!auth.isLoggedIn && !routeIsAllowed(location.pathname)) {
      navigate(getLoginPath({}));
    }
  }, [auth.isLoggedIn, location.pathname]);

  useEffect(() => {
    if (!auth.isProUser && !auth.isLoading) {
      if (disallowedRoutes.includes(location.pathname)) {
        navigate(getProVersionPagePath({}));
      }
    }
  }, [location.pathname, auth.isProUser, auth.isLoading]);

  const generatedRoutes = useRoutes(routes);
  return (
    <Suspense fallback={<LoadingText classNames="text-2xl" />}>
      {generatedRoutes}
    </Suspense>
  );
};
