import config from '~/config';

export function resetConfigValues() {
  config.set('simulation-selectedEvents', []);
  config.set('simulation-selectedEventsTreeView', []);
  config.set('simulation-selectedRunIDs', []);
  config.set('simulation-selectedRunsTreeView', []);
  config.set('simulation-selectedSetupIDs', []);
  config.set('simulation-runSimResults', []);
}
