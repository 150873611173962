import { faSpinner } from '@fortawesome/free-solid-svg-icons';
import { ComponentProps } from 'react';
import Icon from '~/features/common/components/icons/Icon/index';
import { cn } from '~/features/common/utils/tailwind';

const Button = (
  props: ComponentProps<'button'> & {
    condensed?: boolean;
    isLoading?: boolean;
    primary?: boolean;
    testId?: string;
    toolTip?: string;
    type?: 'button' | 'submit' | 'reset';
  }
) => {
  const {
    children,
    className,
    condensed,
    disabled,
    isLoading = false,
    onClick,
    primary = false,
    testId,
    toolTip,
    type = 'button',
    ...otherProps
  } = props;

  return (
    <button
      className={cn(
        'inline-flex flex-1 items-center justify-center rounded border border-gray-300 text-xs font-medium shadow-sm focus:outline-none focus:ring-2 focus:ring-primary focus:ring-offset-2 disabled:cursor-default disabled:opacity-50 dark:border-gray-500 dark:bg-black dark:text-gray-300',
        primary ? 'bg-primary text-white dark:bg-primary' : 'text-gray-700',
        !disabled && !primary && 'hover:bg-gray-50 dark:hover:bg-gray-700',
        !disabled && primary && 'hover:bg-primary-hover',
        condensed ? 'px-1 py-1.5' : 'px-2.5 py-1.5',
        className
      )}
      data-testid={testId}
      disabled={disabled}
      onClick={onClick}
      type={type}
      {...otherProps}
    >
      {isLoading && (
        <Icon
          className="mr-1"
          content={toolTip}
          data-testid="loading-spinner"
          icon={faSpinner}
          spin
        />
      )}
      {children}
    </button>
  );
};

export default Button;
