/* eslint-disable @typescript-eslint/no-unused-vars */
import { useQueryClient } from '@tanstack/react-query';
import { useSubscription } from 'urql';
import {
  TimingServiceStatusUpdatedDocument,
  TimingServiceStatusUpdatedSubscription,
  useGetTimingServiceStatusQuery,
} from '~/generated/graphql';
import { useAuth } from '../../context/AuthContext';
import { logger } from '../../utils/logger';
import { groupTimingServiceStatusUpdates } from './groupTimingServiceStatusUpdates';

export default function useTimingServiceStatus() {
  const queryClient = useQueryClient();
  const auth = useAuth();

  const { data, error, isLoading } = useGetTimingServiceStatusQuery(
    {},
    {
      enabled: !!auth.isLoggedIn,
    }
  );

  function handleTimingStatusUpdatedSubscription(
    _: undefined,
    response: TimingServiceStatusUpdatedSubscription
  ) {
    queryClient.setQueryData(['GetTimingServiceStatus', {}], () => {
      logger.log('subscription timingStatusUpdated received data');
      return {
        timingServiceStatus: response?.timingServiceStatusUpdated,
      };
    });
    return undefined;
  }

  useSubscription(
    {
      query: TimingServiceStatusUpdatedDocument,
    },
    handleTimingStatusUpdatedSubscription
  );
  // Here we combine the active and failed services into a single object
  const groupedTimingStatus = groupTimingServiceStatusUpdates(data);

  return {
    timingStatus: groupedTimingStatus,
    isLoading,
    error,
  };
}
